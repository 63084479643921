
export const environment = {
  production: true,
  name: 'staging',
  API_URL: 'https://staging-dot-lyntonsurveys.appspot.com/',
  BUGSNAG_API_KEY: 'f93fc7fc411b26788039d9e96d0a194c',
  unsplash: {
    access: '609491ba41ac62bd226579b9a0deaee51e557456a0738ed61b328b3e1ed19e60',
    private: '638f0b5f17b5250acaf581c285cec38d8e20b4a032b8c41ffc46b12c10a1acd1',
  },
  logs: {
    sendRequest: false,
    receiveRequest: false,
    dashboard: false,
  },
  firebaseConfig: {
    apiKey: 'AIzaSyC2RZAJu23qzwkyO5Hlgz6R9UqukvZUMlo',
    authDomain: 'lyn-staging-eagle-eye.firebaseapp.com',
    projectId: 'lyn-staging-eagle-eye',
    storageBucket: 'lyn-staging-eagle-eye.appspot.com',
    messagingSenderId: '1025856833908',
    appId: '1:1025856833908:web:cc282ffec47c6955760421',
    measurementId: 'G-0963T7NE79'
  },
  reporting: {
    URL: 'https://lyn-staging-reporting.web.app',
  }
};
